import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [headerStyle, setHeaderStyle] = useState('white');
  const location = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const updateHeaderStyle = () => {
      const isMainRoute = location.pathname === '/';
      if (isMainRoute) {
        const contactSection = document.getElementById('contact-section');

        const observer = new IntersectionObserver(
          ([entry]) => {
            setHeaderStyle(!entry.isIntersecting ? 'transparent' : 'white');
          },
          {
            root: null,
            threshold: 0,
            rootMargin: '100px 0px',
          }
        );

        if (contactSection) {
          observer.observe(contactSection);
        }

        return () => {
          if (contactSection) {
            observer.unobserve(contactSection);
          }
        };
      } else {
        setHeaderStyle('white');
      }
    };

    updateHeaderStyle();

    return () => {
      // Cleanup observer if necessary
    };
  }, [location.pathname]);

  return (
    <div id='header-container' data-isOpen={menuOpen} data-headerstyle={headerStyle}>
      <header id='header'>
        <div id="header-logo">
          <h1 id='header-logo-primary'>NORDICAS</h1>
          <h3>| DEVELOPMENT</h3>
        </div>

        <nav id="header-nav">
          <div className='header-nav-link'>
            <svg className='header-nav-link-icon' viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_57_206)">
                <path d="M8.5 7C8.5 8.06087 8.92143 9.07828 9.67157 9.82843C10.4217 10.5786 11.4391 11 12.5 11C13.5609 11 14.5783 10.5786 15.3284 9.82843C16.0786 9.07828 16.5 8.06087 16.5 7C16.5 5.93913 16.0786 4.92172 15.3284 4.17157C14.5783 3.42143 13.5609 3 12.5 3C11.4391 3 10.4217 3.42143 9.67157 4.17157C8.92143 4.92172 8.5 5.93913 8.5 7Z" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.5 21V19C6.5 17.9391 6.92143 16.9217 7.67157 16.1716C8.42172 15.4214 9.43913 15 10.5 15H14.5C15.5609 15 16.5783 15.4214 17.3284 16.1716C18.0786 16.9217 18.5 17.9391 18.5 19V21" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_57_206">
                  <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                </clipPath>
              </defs>
            </svg>
            <Link className='header-nav-link-text' to="/about">About us</Link>
          </div>

          <div className='header-nav-link'>
            <svg className='header-nav-link-icon' viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_57_198)">
                <path d="M3.5 21.0001H7.5L20.5 8.00006C21.0304 7.46963 21.3284 6.7502 21.3284 6.00006C21.3284 5.24991 21.0304 4.53049 20.5 4.00006C19.9696 3.46962 19.2501 3.17163 18.5 3.17163C17.7499 3.17163 17.0304 3.46962 16.5 4.00006L3.5 17.0001V21.0001Z" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15 5.5L19 9.5" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.5 8L7.5 3L3.5 7L8.5 12" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.5 8L6 9.5" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.5 12L21.5 17L17.5 21L12.5 16" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.5 17L15 18.5" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_57_198">
                  <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                </clipPath>
              </defs>
            </svg>
            <Link className='header-nav-link-text' to="/portfolio">Portfolio</Link>
          </div>

          <div className='header-nav-link'>
            <svg className='header-nav-link-icon' viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_57_192)">
                <path d="M16.5 6H19.5C19.7652 6 20.0196 6.10536 20.2071 6.29289C20.3946 6.48043 20.5 6.73478 20.5 7V18C20.5 18.5304 20.2893 19.0391 19.9142 19.4142C19.5391 19.7893 19.0304 20 18.5 20M18.5 20C17.9696 20 17.4609 19.7893 17.0858 19.4142C16.7107 19.0391 16.5 18.5304 16.5 18V5C16.5 4.73478 16.3946 4.48043 16.2071 4.29289C16.0196 4.10536 15.7652 4 15.5 4H5.5C5.23478 4 4.98043 4.10536 4.79289 4.29289C4.60536 4.48043 4.5 4.73478 4.5 5V17C4.5 17.7956 4.81607 18.5587 5.37868 19.1213C5.94129 19.6839 6.70435 20 7.5 20H18.5Z" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.5 8H12.5" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.5 12H12.5" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.5 16H12.5" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_57_192">
                  <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                </clipPath>
              </defs>
            </svg>
            <Link className='header-nav-link-text' to="/articles">Articles</Link>
          </div>

          {/* <div id="header-nav-language">
            <svg className='header-nav-link-icon' viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_57_185)">
                <path d="M4.5 5H11.5" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 3V5C9.5 9.418 7.261 13 4.5 13" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.5 9C5.5 11.144 8.452 12.908 12.2 13" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.5 20L16.5 11L20.5 20" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19.5999 18H13.3999" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_57_185">
                  <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                </clipPath>
              </defs>
            </svg>

            <p className='header-nav-link-text' id='header-nav-language-selector'>English</p>

            <svg id='header-nav-language-arrow' viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 1L7.5 7L13.5 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div> */}

          <Link className="gray-button" to="/contact">Contact us &gt;</Link>
        </nav>

        <button id="header-burger-menu" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </header>

      <nav id="header-burger-nav">
          <Link className='header-burger-nav-link'>About Us</Link>
          <Link className='header-burger-nav-link'>Portfolio</Link>
          <Link className='header-burger-nav-link'>Articles</Link>
          <Link className='header-burger-nav-link'>Contact Us</Link>
          <Link className='header-burger-nav-link'>English</Link>
      </nav>
    </div>
  );
}

export default Header;