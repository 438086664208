import React from 'react';
import { Link } from 'react-router-dom';

import './Footer.css';

function Footer() {
  return (
    <footer id='footer-container'>
      <div id='footer'>
        <div className="footer-row">
            <h2 id="footer-reach-text">
                <span>You can reach</span>
                us at <a href="mailto:hei@nordicas.no" id="footer-reach-email">hei@nordicas.no</a>
            </h2>
        </div>

        <div className="footer-row"> 
          <span id="footer-address-text">Fjordalléen 16, 0250 Oslo</span>

          <div id='footer-socials'>
            <Link className='footer-social'>
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_61_30)">
                <path d="M4 6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H6C5.46957 20 4.96086 19.7893 4.58579 19.4142C4.21071 19.0391 4 18.5304 4 18V6Z" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 11V16" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 8V8.01" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 16V11" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16 16V13C16 12.4696 15.7893 11.9609 15.4142 11.5858C15.0391 11.2107 14.5304 11 14 11C13.4696 11 12.9609 11.2107 12.5858 11.5858C12.2107 11.9609 12 12.4696 12 13" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_61_30">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
                </defs>
              </svg>
              LinkedIn
            </Link>
            <Link className='footer-social'>
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_61_45)">
                  <path d="M4 8C4 6.93913 4.42143 5.92172 5.17157 5.17157C5.92172 4.42143 6.93913 4 8 4H16C17.0609 4 18.0783 4.42143 18.8284 5.17157C19.5786 5.92172 20 6.93913 20 8V16C20 17.0609 19.5786 18.0783 18.8284 18.8284C18.0783 19.5786 17.0609 20 16 20H8C6.93913 20 5.92172 19.5786 5.17157 18.8284C4.42143 18.0783 4 17.0609 4 16V8Z" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12Z" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M16.5 7.5V7.51" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_61_45">
                  <rect width="24" height="24" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>

              Instagram
            </Link>
            <Link className='footer-social'>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_61_26)">
                <path d="M4 4L15.733 20H20L8.267 4H4Z" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4 20L10.768 13.232M13.228 10.772L20 4" stroke="#BBD1D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_61_26">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
                </defs>
              </svg>
              Twitter
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;