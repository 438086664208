import React from 'react';

import './AboutUsCard.css';

const AboutUsCard = ({ title, text, imageSrc }) => {
    return (
        <div className='about-us-card'>
            <div className='about-us-card-top'>
              <h2 className='font-bold text-xl'>{title}</h2>
              <p>{text}</p>
            </div>
            <div className='about-us-card-bottom'>
              <img src={imageSrc} alt={title} />
            </div>
        </div>
    );
};
      
export default AboutUsCard;