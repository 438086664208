import React, { useState } from 'react';
import './Home.css';

import SectionInfo from '../components/common/SectionInfo';
import ScrollableComponent from '../components/layout/ScrollableComponent';
import AboutUsCard from '../components/layout/AboutUsCard';
import FormInput from '../components/other/FormInput';

export default function Home() {
    const [formData, setFormData] = useState({
        scale: 'medium',
        parts: '',
        name: '',
        email: '',
        description: ''
      });
    
    const handleChange = (e) => {
        const { name, value } = e.target;
    
        setFormData({
          ...formData,
          [name]: value
        });
    };

      
    return (
        <div id='home-container'>
            <div className='home-section' id='intro-section'>
                <video autoPlay muted loop id='background-video'>
                    <source src='./resources/2207466_Person_Walking_1920x1080.mp4' type='video/mp4' />
                    Your browser does not support the video tag.
                </video>
                <div id='home-intro'>
                    <div id='home-intro-text'>
                    <h1 id='home-intro-text-title'>NORDICAS</h1>
                    <h2 id='home-intro-text-subtitle'>DEVELOPMENT</h2>
                    <p id='home-intro-text-description'>Forging resilient technological infrastructure for companies of all sizes.</p>
                    </div>
                    <div id='home-intro-buttons'>
                    <button className='white-button'>Contact Us &gt;</button>
                    <button className='transparent-button'>Read More &gt;</button>
                    </div>
                </div>
            </div>
            <div className='home-section' id='about-us-section'>
                <SectionInfo
                    title="About Us"
                    heading="What We Deliver"
                    content="Keeping up to date with the latest tech trends can have massive performance impacts in every part of a company."
                    extra="We do this for you."
                />

                <ScrollableComponent>
                    <AboutUsCard title={"Rapid construction"} text={"Ved å samle pengene på ett sted kan det genereres renter som bidrar til å betale ned deler eller hele summen av regningen på bolig gjelden."} imageSrc={"./resources/house.png"}/>
                    <AboutUsCard title={"Modern designs"} text={"Ved å samle pengene på ett sted kan det genereres renter som bidrar til å betale ned deler eller hele summen av regningen på bolig gjelden."} imageSrc={"./resources/house.png"}/>
                    <AboutUsCard title={"Technical operations"} text={"Ved å samle pengene på ett sted kan det genereres renter som bidrar til å betale ned deler eller hele summen av regningen på bolig gjelden."} imageSrc={"./resources/house.png"}/>
                    <AboutUsCard title={"test"} text={"Ved å samle pengene på ett sted kan det genereres renter som bidrar til å betale ned deler eller hele summen av regningen på bolig gjelden."} imageSrc={"./resources/house.png"}/>
                    <AboutUsCard title={"test"} text={"Ved å samle pengene på ett sted kan det genereres renter som bidrar til å betale ned deler eller hele summen av regningen på bolig gjelden."} imageSrc={"./resources/house.png"}/>
                    <AboutUsCard title={"test"} text={"Ved å samle pengene på ett sted kan det genereres renter som bidrar til å betale ned deler eller hele summen av regningen på bolig gjelden."} imageSrc={"./resources/house.png"}/>
                </ScrollableComponent>
            </div>

            <div className='home-section flex gap-4 my-10 lg:my-0' id='contact-section'>
                <div className='flex flex-col'>
                    <SectionInfo
                        title="Get in contact"
                        heading="Get a quote."
                        content="We are completely transparent with our pricing model. Enter your specifications and get a quote immediately. Sending this form is non-binding."
                    />

                    <form className='mt-3 flex flex-col lg:grid lg:grid-cols-2 lg:grid-rows-3 gap-2'>
                        <FormInput 
                            type="select" 
                            label="Scale" 
                            id="scale" 
                            name="scale" 
                            value={formData.scale} 
                            options={[{ value: 'medium', label: 'Medium (Blogs, E-commerce)' }]} 
                            onChange={handleChange}
                        />
                        <FormInput 
                            type="number" 
                            label="Parts" 
                            id="parts" 
                            name="parts" 
                            value={formData.parts} 
                            onChange={handleChange}
                        />
                        <FormInput 
                            type="text" 
                            label="Full name" 
                            id="name" 
                            name="name" 
                            value={formData.name} 
                            onChange={handleChange}
                        />
                        <FormInput 
                            type="email" 
                            label="Email" 
                            id="email" 
                            name="email" 
                            value={formData.email} 
                            onChange={handleChange}
                        />
                        <FormInput 
                            type="textarea" 
                            label="Short description" 
                            id="form-short-description" 
                            name="description" 
                            value={formData.description} 
                            onChange={handleChange}
                        />
                    </form>

                    <div className="flex justify-between gap-2.5 mb-4 pt-4">
                        <div className="bg-gray text-white p-6 w-full text-left flex flex-col justify-between">
                            <p className="mb-3">Estimated quote (prepayment)</p>
                            <h1 className="quote-item-subtitle text-3xl lg:text-4xl font-bold">$3 000</h1>
                        </div>
                        <div className="bg-gray text-white p-6 w-full text-left flex flex-col justify-between">
                            <p className="mb-3">Estimated time</p>
                            <h1 className="quote-item-subtitle text-3xl lg:text-4xl font-bold">10 days</h1>
                        </div>
                    </div>

                    <div className="flex gap-2.5">
                        <button className="gray-button">Send form &gt;</button>
                        <button className="transparent-button gray-outline">Contact us &gt;</button>
                    </div>
                </div>
                <div id='contact-section-image'>
                    <img src='./resources/office_building.png' alt='Building Image'/>
                </div>
            </div>

            <div className="bg-blue flex flex-col gap-4 p-8 home-half-section" id="get-in-touch-section">
                <h3 className="">Contact</h3>
                <h1 className="text-4xl font-semibold">Let's get in touch</h1>
                <p className="max-w-sm text-center">
                    Tell us your vision and we’ll come to a conclusion quickly. We’d love to hear from you!
                </p>
                <button className="gray-button mt-2">Contact Us &gt;</button>
            </div>
        </div>
    )
}