const FormInput = ({ type, label, id, name, value, options, onChange }) => (
  <div id={id} className={`flex flex-col ${type == 'textarea' ? 'col-span-2' : ''}`}>
    <label htmlFor={id} className="text-left mb-1.25">{label}</label>

    {type === 'select' ? (
      <select
        id={id}
        name={name}
        className="p-2.5 border-gray border-[3px]"
        value={value}
        onChange={onChange}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    ) : type === 'textarea' ? (
      <textarea
        name={name}
        className="p-2.5 border-3 border-gray border-[3px] resize-y min-h-[40px] max-h-[200px]"
        value={value}
        onChange={onChange}
      />
    ) : (
      <input
        name={name}
        type={type}
        className="p-2.5 border-gray border-[3px]"
        value={value}
        onChange={onChange}
      />
    )}
  </div>
);

export default FormInput;
