import React, { useRef, useState } from "react";

import "./ScrollableComponent.css";

const DefaultPosition = () => {
    return {
        left: 0,
        x: 0
    };
};

const ScrollableComponent = (props) => {
    const ref = useRef(null);

    const [state, setState] = useState({
        isGrabbed: false,
        position: DefaultPosition()
    });

    const onMouseDown = (e) => {
        const x = e.clientX;
        const left = ref.current.scrollLeft;

        console.log("down", x, left);

        setState({
            ...state,
            isGrabbed: true,
            position: { x, left }
        });
    };

    const onMouseUp = () => {
        if (!state.isGrabbed) return;

        setState({
            ...state,
            isGrabbed: false
        });
    };

    const onMouseMove = (e) => {
        if (!state.isGrabbed) return;


        
        const left = Math.max(0, state.position.left + (state.position.x - e.clientX));
        ref.current.scrollLeft = left;
    };

    return (
        <div 
            ref={ref} 
            className="scrollable-component" 
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseUp}
            onMouseMove={onMouseMove}
        >
            {props.children}
        </div>
    );
};

export default ScrollableComponent;
