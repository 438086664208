import React from 'react';
import { useNavigate } from 'react-router-dom';

import './NotFound.css';

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <div id="not-found-container">
      <div id="not-found-box">
        <h1>404 Not Found</h1>

        <p>Sorry, the page you are looking for does not exist.</p>
 
        <button className="gray-button mt-8" onClick={() => navigate('/')}>Go back to the main page &gt;</button>
        </div>
    </div>
  );
}