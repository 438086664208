import React from 'react';
import PropTypes from 'prop-types';
import './SectionInfo.css'; 

const SectionInfo = ({ title, heading, content, extra = null }) => {
  return (
    <div className='section-info'>
      <p className='section-info-title'>{title}</p>
      <h2 className='section-info-heading'>{heading}</h2>
      <p>{content}</p>
      <p>{extra}</p>
    </div>
  );
};

SectionInfo.propTypes = {
  title: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default SectionInfo;